var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"calculate-widths":"","item-key":"invoiceNo","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{
    showCurrentPage: true,
    showFirstLastPage: true,
    'items-per-page-options': _vm.$_item_per_page
  }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.invoiceGroup",fn:function(ref){
  var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.renamePeriode(item.invoiceGroup)))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(item.periodStart))+" s/d "+_vm._s(_vm.dateFormat(item.periodEnd)))])]}},{key:"item.totalAmount",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.totalAmount)))]),_c('br'),(item.invoiceType === 'Pengiriman')?_c('span',{staticClass:"size-12"},[_vm._v(" "+_vm._s(item.totalShipment)+" Pesanan ")]):_c('span',{staticClass:"size-12"},[_vm._v(" "+_vm._s(item.totalShipment)+" Biaya ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }