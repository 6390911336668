<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="5" md="8">
        <h4>{{ $_strings.invoice.INVOICE_SHIPPER_CANCEL }}</h4>
      </v-col>
      <v-col>
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          v-model="search"
          :placeholder="$_strings.invoice.SEARCHNO"
          @keyup.enter="handleSearch"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="handleSearch">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-sheet class="mt-2 pa-2">
      <FilterList :filter="filter" @setFilter="setFilter"/>
      <TableList :key="key_list" :filter="filter"/>
    </v-sheet>
  </v-container>
</template>
<script>
import { skipEmptyStringObject } from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import FilterList from './FilterList';
import TableList from './TableList';

export default {
  components: {
    TableList,
    FilterList,
  },
  data() {
    return {
      key_list: 0,
      search: this.$route.query.search || '',
      filter: {
        billedToCompanyId: +this.$route.query.billedToCompanyId || '',
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
      },
    };
  },
  methods: {
    handleSearch() {
      const filter = {
        search: this.search,
      };
      this.setFilter(filter);
    },
    setFilter(newFilter) {
      const _newFilter = skipEmptyStringObject({
        ...this.$route.query,
        ...newFilter,
        page: 1,
      });
      const oldQuery = JSON.stringify(this.$route.query).replace(/"/g, '');
      const newQuery = JSON.stringify(_newFilter).replace(/"/g, '');
      if (oldQuery !== newQuery) {
        this.$router.replace({
          query: {
            ..._newFilter,
          },
        });
        this.key_list += 1;
      }
    },
  },
};
</script>
